import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import Breadcrumb from "../components/Breadcrumb";

class Post extends Component {

    render() {
      this.props.pageContext.pageTitle = this.props.data.wpNewsPost.Meta.title ? this.props.data.wpNewsPost.Meta.title : this.props.data.wpNewsPost.title;
      this.props.pageContext.date = this.props.data.wpNewsPost.date;
      const blocks = buildBlocks(this.props.data.wpNewsPost.blocks, {}, this.props.pageContext);

      return (
      <Layout
        meta={{
          ...this.props.data.wpNewsPost.Meta,
          canonical: this.props.data.wpNewsPost.uri
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpNewsPost.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="news-post"
          current={{
            title: this.props.data.wpNewsPost.title,
            slug: this.props.data.wpNewsPost.slug,
            uri: this.props.data.wpNewsPost.uri
          }}
        />
        {blocks}
      </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpNewsPost(id: {eq: $id}) {
      id
      slug
      title
      uri
      date(formatString: "DD MMMM YYYY")
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }    
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
}
`;

export default Post
